import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import CustomButton from '../../components/custom-button';
import '../../styles/applications/turf-ornaments.scss';

const TurfOrnaments = () => {
  return (
    <Layout>
      <SEO
        title="Enhance Turf & Ornamental | Ostara"
        description="Grow turf more sustainably, with faster recovery time, improved stand and color, and reduced leaching and runoff using Root-Activated technology. "
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhance Turf & Ornamental Applications"
      />
      <main>
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[12, 12, 12, 100]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack maxW="50rem" minH="250px" spacing={6}>
                  <h1>Enhance Turf & Ornamental Applications</h1>
                  <p>
                    Grow turf more sustainably, with faster recovery time,
                    improved stand and color, and reduced leaching and runoff.
                  </p>
                  <div className="button-wrappers">
                    <CustomButton>
                      <Link to="/contact">CONTACT US</Link>
                    </CustomButton>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../../images/turf-ornaments-hero-img-min.jpeg"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        <Box w="100%" p={4} className="benefits-body-container">
          <Flex direction="column">
            <Container
              className="benefits-body-content benefits-body-first-part"
              maxW={1400}
            >
              <Container maxW="container.md">
                <h3>
                  Crystal Green<sup>®</sup> Fertilizers Enhance Turf, Operations
                  and Environmental Stewardship
                </h3>
                <p>
                  Crystal Green’s Crop Driven Release™ process releases
                  nutrients in response to the organic acids produced by growing
                  turf, keeping phosphorus available to support root density,
                  vibrancy and stress tolerance.
                </p>
              </Container>
              <Container
                className="benefits-body-icons"
                maxW="container.lg"
                marginBottom={20}
              >
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/prevent-soil-fixation-nutrients-on-demand-thumb.png"
                    alt="Ostara icons"
                    width={200}
                    height={200}
                  />
                  <h5>
                    Nutrition on
                    <br />
                    Demand
                  </h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/turf-ornaments-icon2.png"
                    alt="Ostara icons"
                    width={200}
                    height={200}
                  />
                  <h5>
                    Greener
                    <br />
                    Grass
                  </h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/denser-root.png"
                    alt="Ostara icons"
                    width={200}
                    height={200}
                  />
                  <h5>
                    Denser Root
                    <br />
                    Mass
                  </h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/easy-app.png"
                    alt="Ostara icons"
                    width={200}
                    height={200}
                  />
                  <h5>
                    Easy
                    <br />
                    Application
                  </h5>
                </div>
                <div className="benefits-icon">
                  <StaticImage
                    placeholder="transparent"
                    src="../../images/turf-ornaments-icon5.png"
                    alt="Ostara icons"
                    width={200}
                    height={200}
                  />
                  <h5>
                    Environmentally
                    <br />
                    Responsible Fertilizer
                  </h5>
                </div>
              </Container>
            </Container>
          </Flex>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <StaticImage
                placeholder="transparent"
                src="../../images/prevent-soil-fixation-nutrients-on-demand.png"
                alt="Ostara icons"
              />
              <Flex className="benefits-body-content-2">
                <h3>Nutrition on Demand</h3>
                <p>
                  Improve nutrient efficiency with Crystal Green, the first Crop
                  Driven Release™ fertilizer that releases nutrients gradually,
                  feeding turf according to root demand.
                </p>
                <Link to="/product/crystal-green-pearl">
                  Explore Fertilizer
                </Link>
              </Flex>
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="turf-body-content-container" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={12}>
              <Flex className="turf-body-content">
                <h3>Greener Grass</h3>
                <p>
                  Enhance the natural color of turf with fertilizer that really
                  does make grass greener. In addition to phosphorus and
                  nitrogen, Crystal Green fertilizers contain 10% magnesium,
                  which is essential for capturing the sun’s energy for
                  photosynthesis, which creates a vibrant color.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
              <div>
                <StaticImage
                  placeholder="transparent"
                  src="../../images/diagram-elements.png"
                  alt="Ostara chart"
                  height={300}
                />
              </div>
            </SimpleGrid>
          </Container>

          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="benefits-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <Flex className="benefits-body-content-2">
                <h3>Denser Root Mass</h3>
                <p>
                  Build denser root mass for improved wear and stress tolerance
                  with Crystal Green’s plant available phosphate fertilizer.
                  When soil doesn’t get enough phosphorus, roots have a
                  difficult time establishing and turf can lose vibrancy and
                  struggle to recover from stress and disease.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
              <StaticImage
                placeholder="transparent"
                src="../../images/benefits-graphic4.png"
                alt="Ostara icons"
              />
            </SimpleGrid>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>
          <Container className="benefits-body-content-3-container" maxW={1400}>
            <Flex className="benefits-body-content-3">
              <h3>Easy Application</h3>
              <p>
                Streamline operations with a fertilizer that only needs to be
                applied twice per year, ideally during aerification as straight
                product or with sand. If applied outside of aerification,
                Crystal Green will still penetrate the canopy and have minimal
                mower pick-up.
              </p>
              <Link to="/product/crystal-green">Explore Fertilizer</Link>
            </Flex>
          </Container>
          <Container maxW={1400}>
            <hr className="custom-divider" />
          </Container>

          <Container className="benefits-body-content" maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <StaticImage
                placeholder="transparent"
                src="../../images/turf-ornaments-img3.png"
                alt="Ostara icons"
              />
              <Flex className="benefits-body-content-2">
                <h3>Environmentally Responsible Fertilizer</h3>
                <p>
                  Enhance vibrancy and streamline operations while creating a
                  positive environmental impact. Crystal Green is the ONLY
                  environmentally responsible fertilizer, with strategies to
                  prevent excess nutrients and reduce the risk of runoff using
                  Crop Driven Release™.
                </p>
                <Link to="/product/crystal-green">Explore Fertilizer</Link>
              </Flex>
            </SimpleGrid>
          </Container>
        </Box>

        {/* <Box w="100%" p={4} className="ornaments-data-container">
          <Container maxW={1400}>
            <h4>THE DATA</h4>
            <h2>Cornell University: Root Mass Study</h2>
            <SimpleGrid
              columns={{ md: 1, lg: 2 }}
              className="ornaments-data-info"
              spacing={6}
            >
              <Flex className="ornaments-data-content">
                <p>
                  Each granule of Crystal Green delivers phosphorus, plus
                  nitrogen and magnesium, for up to 30% more root length and
                  density.
                </p>
              </Flex>
              <Flex direction="column" alignItems="center">
                <StaticImage
                  placeholder="transparent"
                  src="../../images/mean-root-biomass.png"
                  alt="Ostara img"
                  className="ornaments-research-img"
                />
              </Flex>
            </SimpleGrid>
          </Container>
        </Box>
        <Box w="100%" p={4} className="ornaments-research-container">
          <Container maxW={1400}>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={24}>
              <StaticImage
                placeholder="transparent"
                src="../../images/before-after.png"
                alt="Ostara img"
                className="ornaments-research-img"
              />
              <Flex>
                <Stack className="benefits-research-detail" spacing={6}>
                  <h4>CASE STUDY</h4>
                  <Link to="/contact">
                    UK Landscape Company Restores Turf Health in Highly Acidic
                    Soil with Severe Phosphorus Deficiency
                  </Link>
                  <p>
                    The challenges growers face in every area of the UK to
                    produce optimum turf are varied, but the Isle of Man has its
                    own unique pain points, as Quest Landscapes discovered when
                    reseeding an area being grown for turf. With the help of a
                    complete diagnostic recourse and input from Isle of Man
                    Farmers, Quest Landscapes was able to completely transform
                    its turn using Crystal Green fertilizer, restoring health
                    and vigor.
                  </p>
                </Stack>
              </Flex>
            </SimpleGrid>
          </Container>
        </Box> */}
        <Container
          maxW={1400}
          className="quotation-container"
          marginBottom={100}
        >
          <h2>
            Given its unique mode of action, Crystal Green minimized phosphorus
            tie-up in a highly acidic soil which was the main component in the
            successful restoration of the turf.
          </h2>
          <div className="quotation-author">
            <h5>Mike Atherton</h5>
            <p>Isle of Man Farmers</p>
          </div>
        </Container>
        <Box w="100%" p={4} className="ornaments-body-hero-container">
          <div className="overlay" />
          <Container maxW={1400}>
            <Stack
              paddingTop={[16, 150]}
              paddingBottom={[16, 150]}
              maxW="45rem"
              spacing={6}
            >
              <h2>Enhance Your Turf with Crystal Green Fertilizers</h2>
              <p>
                Crystal Green fertilizers make grass greener and more resilient
                while you benefit from easier application and environmental
                stewardship.
              </p>
              <CustomButton maxW={180}>
                <Link to="/product/crystal-green">LEARN MORE</Link>
              </CustomButton>
            </Stack>
          </Container>
        </Box>
      </main>
    </Layout>
  );
};

export default TurfOrnaments;
